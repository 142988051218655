import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './index.module.less';
import { AppSettings, pageMetadataMap, PageType } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

const GlobalFooter = ({
  show = true,
  className,
  pageType,
}: {
  show?: boolean;
  className?: string;
  pageType: PageType;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  if (!show) {
    return <></>;
  }
  const cls = classNames(styles.globalFooterWarp, className);
  // const pathname = (typeof window !== 'undefined') ? window.location.pathname : "/"

  return (
    <div className={cls}>
      <div className={styles.titleContainer}>
        {/* GPTs Logo */}
        <div style={{ height: '32px' }}>
          <Image
            className={styles.logo}
            src={AppSettings.logoPath}
            alt={AppSettings.name}
            loading="lazy"
            width={32}
            height={32}
          />
          <Image
            src={AppSettings.namePath}
            alt={AppSettings.name}
            style={{ height: '32px' }}
            width={154}
            height={32}
            loading="lazy"
          />
        </div>
        <div className={styles.routerSection}>
          <Link href={`/#features`} className={styles.link}>
            {t('Features')}
          </Link>
          <Link href={'/#use-cases'} className={styles.link}>
            {t('Use Cases')}
          </Link>
          <Link href={`/pricing`} className={styles.link}>
            {t('Pricing')}
          </Link>
          <Link href={`/blog`} className={styles.link}>
            {t('blog')}
          </Link>
        </div>

        <div className={styles.section}>
          <Link href="https://x.com/InstantKnowIK" target="__block" className={styles.link}>
            <img src="/images/layouts/x-link-icon.webp" alt="x" />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=61569090703388"
            target="__block"
            className={styles.link}
          >
            <img src="/images/layouts/fb-link-icon.webp" alt="facebook" />
          </Link>
        </div>
      </div>

      <div className={styles.bottomDescContainer}>
        <span
          dangerouslySetInnerHTML={{
            __html: t('IfYouHaveAnyIssuesPleaseClickThisFormToProvideFeedback_Desc'),
          }}
        />
      </div>
      {Object.entries(PageType)
        .filter(([, value]) => value !== pageType)
        .map(([key, value]) => {
          const path = `${router.locale && router.locale !== 'en' ? `/${router.locale}` : ''
            }${value === PageType.Normal ? '' : `/${value}`}`;
          const pageMetaData = pageMetadataMap[value];
          const content = t(pageMetaData.titleKey);
          return (
            <div className={styles.bottomDescContainer} key={key}>
              <a href={path}>
                <span>{content}</span>
              </a>
            </div>
          );
        })}

      <div className={styles.bottomContainer}>
        <div className={styles.subTitleContainer}>All rights reserved. © 2024 Instantknow.net</div>

        <div className={styles.section}>
          <Link href={`/privacy-policy`} className={styles.link}>
            {t('privacyPolicy')}
          </Link>
          <Link href={`/terms-of-use`} className={styles.link}>
            {t('termsOfUse')}
          </Link>
        </div>
        <div className={styles.subTitleContainer}>All rights reserved. © 2024 Instantknow.net</div>
      </div>
    </div>
  );
};

export default GlobalFooter;
